import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Row, Col, Card, Button } from "react-bootstrap"
import CardDeck from "react-bootstrap/CardDeck"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import Layout from "../../components/layout"
import SEO from "../../components/seo"


const AllHats = () => {

  const { gcms: { productsApi } } = useStaticQuery(pageQuery)

  // state of filter 
  const [filter, updateFilter] = useState("")
  // state of products shown
  const [products, setProducts] = useState([])

  // initially shows all products
  useEffect(() => {
    const productsList = productsApi.map(product => ({
      ...product,
      id: product.id,
      slug: product.slug,
      category: product.category,
      name: product.name,
      imgSrc: product.photos[0].node.childImageSharp.fluid,
      imgKey: product.photos[0].node.childImageSharp.id
    }))
    setProducts(productsList)
  }, [productsApi])

  // watches the filter state to filter products by category
  useEffect(() => {
    setProducts([])
    const filtered = productsApi.map(product => ({ ...product, filtered: product.category.includes(filter) }))
    setProducts(filtered);
  }, [filter])

  return (
    <Layout>
      <SEO title="Browse Products" />
      <Row>
        <Col className="mb-5">
          <h1>OUR PRODUCTS</h1>
          <p className="lead">Check out our most popular items below! For our complete inventory, visit us in store.</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="mb-0">Filter by category:</p>
        </Col>
      </Row>

      <Row className="pb-3">
        <Col>
          <Button variant="outline-secondary" className="m-2" active={filter === ""} onClick={() => updateFilter("")}>All</Button>
          <Button variant="outline-secondary" className="m-2" active={filter === "bucket-hats"} onClick={() => updateFilter("bucket-hats")}>Bucket Hats</Button>
          <Button variant="outline-secondary" className="m-2" active={filter === "fedoras-and-trillbys"} onClick={() => updateFilter("fedoras-and-trillbys")}>Fedoras and Trillbys</Button>
          <Button variant="outline-secondary" className="m-2" active={filter === "fitted"} onClick={() => updateFilter("fitted")}>Fitted</Button>
          <Button variant="outline-secondary" className="m-2" active={filter === "ivy"} onClick={() => updateFilter("ivy")}>Ivy Caps</Button>
          {/* <Button variant="outline-secondary" className="m-2" active={filter === "kids"} onClick={() => updateFilter("kids")}>Kids</Button> */}
          <Button variant="outline-secondary" className="m-2" active={filter === "snapback"} onClick={() => updateFilter("snapback")}>Snapback</Button>
          <Button variant="outline-secondary" className="m-2" active={filter === "strapback"} onClick={() => updateFilter("strapback")}>Strapback</Button>
          <Button variant="outline-secondary" className="m-2" active={filter === "summer"} onClick={() => updateFilter("summer")}>Summer</Button>
          <Button variant="outline-secondary" className="m-2" active={filter === "velcro"} onClick={() => updateFilter("velcro")}>Velcro</Button>
          <Button variant="outline-secondary" className="m-2" active={filter === "winter"} onClick={() => updateFilter("winter")}>Winter</Button>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex">
            <CardDeck
              className="mx-auto justify-content-center"
            >
              {products.map(item => item.filtered === true ? (
                < Link key={item.slug} to={`/products/${item.slug}`}>
                  <Card className="mb-3 shadow" key={item.id} style={{ width: "15em" }}>
                    <Img
                      fluid={item.photos[0].node.childImageSharp.fluid}
                      key={item.photos[0].node.childImageSharp.id}
                    />
                    <Card.Body>
                      <Card.Title>{item.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              ) : "")}
            </CardDeck>
        </Col>
      </Row>
    </Layout >
  )
}

export default AllHats

export const pageQuery = graphql`
query ProductQuery {
  gcms {
    productsApi(orderBy: name_ASC) {
      id
      name
      slug
      category
      photos(first: 1) {
        url
        node {
          childImageSharp {
            id
            fluid (maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}`

AllHats.propTypes = {
  gcms: PropTypes.shape({
    productsApi: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      category: PropTypes.string
    })
  })
}